@mixin textInfo {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.info-container {
  background: white;
  //border: 1px solid #E1E9F4;
  border-radius: 12px;
  padding: 18px 8px;

  &_deleted {
    border: 1px solid $deletedColor;
  }

  &_added {
    border: 1px solid $orangeActive;
  }

  &__status {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    &_margin {
      margin-bottom: 14px;
    }

    &_success {
      color: $reviewed;
    }

    &_error {
      color: $darkError;
    }

    &-img {
      margin-left: 4px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: $darkBlue;

    &_deleted {
      color: $deletedColor;
    }

    &_added {
      color: $orangeActive;
    }

    &-required {
      color: $darkError;
    }
  }

  &__total-text {
    font-weight: 600;
    font-size: $veryBigSize;
    line-height: 24px;
    color: $blueGray;

    &_margin {
      margin-top: 14px;
    }
  }

  &__total-description {
    font-weight: 600;
    font-size: $smallSize;
    line-height: 17px;
    color: $greyForPlaceholders;

    &_margin {
      margin-top: 2px;
    }
  }

  &__deleted-text {
    @include textInfo();
    color: $deletedColor;
  }

  &__added-text {
    @include textInfo();
    color: $orangeActive;
  }
}