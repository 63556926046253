* {
  font-family: "Proxima Nova", serif;
}

html, body, #root, .app_wrapper, .container {
  min-height: 100%;
  height: 100%;
  width: 100%;
}

.image {
  object-fit: cover;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.pt-18px {
  padding-top: 18px;
}

.mt-18px {
  margin-top: 18px;
}

.mt-1 {
  margin-top: 0.25rem;
}

.ml-3 {
  margin-left: 0.75rem;
}