body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

ul, ol{
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  -webkit-font-smoothing: subpixel-antialiased;
  font-family: "Noto Sans", sans-serif;
  box-sizing: border-box;
}

.smoothed {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

blockquote, q {
  quotes: none;
}

blockquote {
  &:before, &:after {
    content: none;
  }
}

q {
  &:before, &:after {
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset {
  display: contents;
}

// Hide input type number arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

// remove iphone safari input rounding
input {
  border-radius: 0;
  -webkit-appearance: none;
}

input, textarea {
  margin: 0;
  padding: 0;
}

// remove input shadow in mobile safari
input[type=text] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

// hide input "date" calendar icon
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  //display: none;
  opacity: 0;
  -webkit-appearance: none;
}