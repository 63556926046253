.expenses-other-info__button {
  padding: 13px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F7F9FD;
  border-radius: 10px;
  border: 1px solid #D4D9DE;

  &_active {
    border-color: #FDD7C4;
  }
}

@mixin ScanReceiptField {
  padding: 21px 0;
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scan-receipt {

  &-field {
    border: 1px dashed $orangeActive;
    background: #F7F9FD;
    @include ScanReceiptField();
    
    &_error {
      border: 1px solid $darkError;
    }
  }

  &_filled {
    background: #FAA77E;
    color: white;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    @include ScanReceiptField();

    &-image {
      margin-right: 10px;
    }

    &_disabled {
      background-color: $greyForPlaceholders;
    }
  }
}

$expenses-modal-header-height: 50px;
$expenses-modal-footer-height: 57px;

.expenses-modal {
  &__back-header {
    display: flex;
    background: $commonWhite;
    align-items: center;
    height: $expenses-modal-header-height;
    width: 100%;
    flex-shrink: 0;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: $maxWidth;
  }

  &__back-button {
    display: flex;
    align-items: center;
    background-color: transparent;
    font-size: $bigSize;
    line-height: $bigSizeLineHeight;
    color: $darkBlue;
    position: absolute;
    left: 15px;
  }

  &__back-text {
    text-align: center;
    width: 100%;
    font-weight: 600;
  }

  &__gallery {
    height: calc(100vh - #{$expenses-modal-header-height} - #{$expenses-modal-footer-height});
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    margin-top: $expenses-modal-header-height;

    &_item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;

      &-img {
        height: 100%;
        object-fit: contain;
        max-height: 76vh;

        //@media screen and (max-width: 375px) {
        //  max-height: 69vh;
        //}
      }
    }
  }

  &__footer {
    background: $commonWhite;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $expenses-modal-footer-height;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: $maxWidth;
    //@media screen and (max-width: 374px) {
    // bottom: 100px;
    //}
  }
}
