.input {
  @include focus;

  &_main {
    background: $inputBackground;
    border: 1px solid $inputBorderGreyColor;
    box-sizing: border-box;
    border-radius: 10px;
  }
}

.input-field {
  display: flex;
  flex-direction: column;
  width: 100%;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  &--size {
    &-sm {

    }

    &-lg {

    }
  }

  &__label {
    font-weight: 600;
    font-size: $smallSize;
    line-height: 17px;
    color: $blueGray;
    padding-bottom: 4px;

    &-helper {
      color: $greyForPlaceholders;
    }
  }

  &__item {
    font-size: $bigSize;
    width: 100%;
    background: none;
    outline: none;
    border: none;
    resize: none;
    color: $darkBlue;
    -webkit-text-fill-color: $darkBlue;
    opacity: 1; /* required on iOS */

    &::placeholder {
      color: $greyForPlaceholders;
      -webkit-text-fill-color: $greyForPlaceholders;
      opacity: 1;
    }

    &:disabled {
      color: $darkBlue;
    }

    &-container {
      display: flex;
      justify-content: space-between;
      padding: 16px;
      background: $inputBackground;
      border: 1px solid $inputBorderGreyColor;
      box-sizing: border-box;
      border-radius: 10px;

      &_date {
        padding: 9px 16px;
      }

      &-error {
        border-color: $error;
      }

      &_edited {
        border-color: $editedBorderColor;
      }
    }

    &_old-value {
      font-size: $bigSize;
      line-height: 22px;
      text-decoration: line-through;
      color: $greyForPlaceholders;
      margin-right: 10px;
    }
  }
}

.react-date-picker--disabled {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
}

.custom-date-picker {
  background: transparent !important;

  & .react-calendar {
    border: 1px solid white;
    border-radius: 4px;
    box-shadow: 4px 4px 19px #878383;
  }

  & input {
    color: $darkBlue;
    font-size: 18px;

    &::placeholder {
      font-size: 18px;
      color: $greyForPlaceholders;
      -webkit-text-fill-color: $greyForPlaceholders;
      opacity: 1;
    }
  }

  & .react-calendar__tile {
    font-size: 1rem;
    line-height: 1.125em;
    color: $darkBlue;
  }

  & .react-calendar__month-view__days__day--weekend {
    color: $orangeActive;
  }

  & .react-calendar__month-view__days__day--neighboringMonth {
    color: $greyForPlaceholders;
  }

  & .react-calendar__navigation__label__labelText {
    font-size: 1rem;
    color: $darkBlue;
  }

  & .react-calendar__navigation {
    margin-top: 16px;

    & * {
      font-size: 16px;
    }
  }

  & .react-calendar__navigation__arrow {
    font-size: 1.5rem !important;
    color: $darkBlue;
  }

  & .react-date-picker__inputGroup__leadingZero {
    color: $darkBlue;
    font-size: 18px;
  }
  
  & .react-calendar__tile--now {
    background: none;
  }

   & .react-calendar__tile--active {
     background: $buttonBlueColor;
     color: white;
   }

  & .react-date-picker__inputGroup__input:invalid {
    background: none;
  }

  & .react-date-picker__inputGroup  {
    padding: 0;
  }

  & .react-date-picker__wrapper {
    border: none;
  }

  & .react-date-picker__button {
    padding-right: 0;
  }
}