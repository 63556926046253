$timePickerItemHeight: 30px;
$timePickerContainerHeight: $timePickerItemHeight * 3;

.time-picker {
  display: flex;
  justify-content: center;
  align-items: center;
  //height: $timePickerContainerHeight;
}

.time-picker__container {
  height: $timePickerContainerHeight;
  overflow: hidden;
  width: 100%;
  background: $inputBackground;
  border: 1px solid $inputBorderGreyColor;
  border-radius: 10px;

  &_error {
    border-color: $error;
  }
}

.time-picker__wrapper {
  width: 100%;
}

.time-picker__container > div {
  width: 100%;
  height: 100%;
  padding: 0 8px;
}

@mixin timePickerFonts {
  color: $darkBlue;
  font-weight: bold;
  font-size: 20px;
}

.picker-divider {
  margin: 35px 11px 0;
  @include timePickerFonts();
}

.time-picker__element {
  text-align: center;
  width: 100%;
  height: $timePickerItemHeight;
  line-height: $timePickerItemHeight;
  border-radius: 7px;

  @include timePickerFonts();
}

.time-picker__container .swiper-slide-active .time-picker__element {
  background-color: $mainColor;
}
