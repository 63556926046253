.page-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__text {

  }

  &__images {
    display: flex;
    align-items: center;
  }

  &__image {
    width: 28px;
    height: 28px;
    cursor: pointer;

    &_small {
      width: 24px;
      height: 24px;
      margin-right: 14px;
      cursor: pointer;
    }
  }

  &_padding {
    padding-left: 8px;
    padding-right: 8px;
  }
}