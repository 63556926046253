#modal-root {
  position: relative;
  z-index: 999;
}

.modal-root {
  background-color: rgba(0,0,0,0.8);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    height: 100vh;
  }
}

html.modal-is-locked,
html.modal-is-locked body {
  /* want to fix the height to the window height */
  height: calc(var(--window-inner-height) - 1px);

  /* want to block all overflowing content */
  overflow: hidden;
  position: fixed;

  /* want to exclude padding from the height */
  box-sizing: border-box;
}

.modal-is-open {
  display: block;
}

.react-hot-toast {
  position: absolute;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 6px;
  width: max-content;

  &_error {
    background: $toastError;
  }
  &_success {
    background: $toastSuccess;
  }

  &__text {
    padding: 12px 22px;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;


    &_error {
      color: white;
    }
    &_success {
      color: $darkBlue;
    }
  }
}