.login {
  display: flex;
  flex-flow: column;
  max-width: $maxWidth;
  height: 100%;
  width: 100%;

  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__image {
    width: 131px;
    height: 32px;

    &-container {
      //padding-top: 74px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 1 auto;
      flex-flow: column;
    }

  }

  &__back-button {
    margin-top: 10px;
    padding: 5px;
    display: flex;
    align-items: center;
    background-color: transparent;
    font-size: $bigSize;
    line-height: $bigSizeLineHeight;
    color: $darkBlue;
    font-weight: 500;

    &-container {
      height: 74px;
      width: 100%;
    }

    &-img {
      margin-right: 11px;
      width: 7px;
      height: 14px;
    }
  }

  &__content {
    flex: 1 1 auto;
  }

  &__lang-button {
    background: $mainColor;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 17px 0;
    border-radius: 8px;
    border: 1px solid #C2CFE5;
    color: $darkBlue;
    font-weight: 600;
    margin-bottom: 18px;
    font-size: 1rem;

    &:last-child {
      margin-bottom: 0;
    }

    &:active {
      transform: scale(0.9);
    }

    &-image {
      width: 26px;
      height: 26px;
      margin-right: 11px;
    }
  }

  &__phone-input {
    padding: 16px 0;
    font-size: $bigSize;
    text-align: center;
    color: $darkBlue;
    font-weight: 500;

    &-container {
      display: flex;
      flex-direction: column;
    }

    &-submit {
      width: 100%;
      margin-top: 18px;
    }

    &_margin {
      margin-top: 56px;
    }

    &_error {
      border-color: $error;
      color: $error;
    }
  }

  &__title {
    &-description {
      color: $blueGray;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      margin-top: 8px;
    }
  }

  &__code {

    &-input {

      & input {
        //border: none;
        //background: transparent;
        //border-bottom: 2px solid #A0ABB6;
        //padding: 0 4px;
        //margin-right: 8px;
        //text-align: center;
        //font-size: $bigSize;
        //line-height: 18px;
        //color: $darkBlue;
        //outline: none;

        &:focus {
          border-bottom-color: $orangeActive !important;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &-container {
      background: $inputBackground;
      border: 1px solid $inputBorderGreyColor;
      border-radius: $tinySize;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 0;
      margin-top: 32px;

      &_error {
        border-color: $error;

        & .login__code-input input {
          border-bottom-color: $error;
          color: $error;
        }
      }
    }
  }

  &__timer {
    color: $blueGray;
    font-weight: 600;
    margin-left: 6px;
  }
}