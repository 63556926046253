.container {
  padding: 0 18px;
}

.container_small {
  padding: 0 10px;

  &_added_padding {
    padding: 0 8px;
  }
}

.container_big {
  padding: 0 18px;
}