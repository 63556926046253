.custom-swal {

  &__container {
    background: rgba(51, 51, 51, 0.8) !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  &__buttons {
    order: 2 !important;
    width: 100% !important;
    flex-wrap: nowrap !important;
    padding: 0 18px !important;
  }

  &__btn {
    border-radius: 6px !important;
    padding: 16px 0 !important;
    border: 1px solid $buttonBlueColor !important;
    cursor: pointer !important;
    width: 100% !important;

    &-confirm {
      color: $buttonBlueColor !important;
      background-color: white !important;

      &:hover {
        background-color: $buttonBlueColor !important;
      }
    }

    &-cancel {
      background: $buttonBlueColor !important;

      &:hover {
        color: $buttonBlueColor !important;
        background-color: white !important;
      }
    }
  }

  &__title {
    color: $darkBlue !important;
    font-family: "Proxima Nova", serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 21px !important;
    line-height: 26px !important;
    margin-bottom: 6px !important;
    padding-top: 24px !important;
  }

  &__text {
    color: $darkBlue !important;
    margin-top: 0 !important;

    &_padding_top {
      padding-top: 24px !important;
    }

    &_big {
      font-size: 21px;
    }

    &_bold {
      font-weight: bold !important;
    }
  }

  &__icon {
    order: 1 !important;
    margin-top: 20px !important;
    margin-bottom: 0 !important;
  }
}