.text-center {
  text-align: center;
}

.all-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.w-full {
  width: 100%;
}

.container_x_scrollable {
  display: flex;
  overflow-x: auto;
  width: 100%;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.display-none {
  display: none;
}

.h-screen {
  height: 100vh;
}

.flex-col {
  flex-direction: column;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.font-bold {
  font-weight: bold;
}

.relative {
  position: relative;
}