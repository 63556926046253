$mainColor: #D6E1F0;
$darkBlue: #0D3053;
$error: #EB5757;
$blueGray: #788797;
$commonWhite: #FBFCFD;
$darkBlueBorder: #343D46;
$orangeActive: #FB8D57;
$mainBackground: #DDE6F3;
$greyForPlaceholders:  #A0ABB6;
$inputBackground: #F6F9FD;
$inputBorderGreyColor: #CEDCED;
$buttonBlueColor: #135297;
$reviewed: #53A600;
$darkError: #C70303;
$oldBlue: #EBF3FF;
$deletedColor: #A90101;
$editedBorderColor: #105090;
$toastError: #F05151;
$toastSuccess: #E3FEC9;
$buttonActive: #01438C;

$tinySize: 10px;
$verySmallSize: 12px;
$smallSize: 14px;
$bigSize: 18px;
$veryBigSize: 20px;
$bigSizeLineHeight: 22px;

$bottomNavHeight: 50px;

$smallScreenSize: 374px;
$verySmallScreen: 359px;
$mediaTinySize: 320px;

$maxWidth: 500px;
