@mixin buttonCommon {
  text-align: center;
  padding: 14px 0;
  font-weight: bold;
  font-size: $bigSize;
  line-height: $bigSizeLineHeight;
  border: 1px solid $darkBlueBorder;
}

.button {
  background: transparent;
  border: none;

  &_full {
    width: 100%;
  }

  &_dark {
    background: $darkBlueBorder;
    border-radius: $tinySize;
    @include buttonCommon;
    color: #C2C2C2;

    &:hover:enabled {
      background: $buttonActive;
      color: $commonWhite;
    }

    &:active:enabled {
      background: #153A62;
      color: $commonWhite;
    }
  }

  &_common {
    background: $darkBlueBorder;
    border-radius: $tinySize;
    @include buttonCommon;
    color: #C2C2C2;

    &_active {
      background: $buttonActive;
      color: $commonWhite;
    }

  }

  &_transparent {
    background: transparent;
    border-radius: $tinySize;
    color: $darkBlue;
    @include buttonCommon;

    &:hover {
      background: #D2D7E1;
    }
  }

 @include focus;
}

.button-back-container {
  display: flex;
  align-items: center;
  background-color: $commonWhite;
  font-size: $bigSize;
  line-height: $bigSizeLineHeight;
  color: $darkBlue;
  max-width: $maxWidth;
  width: 100%;
  position: relative;

  &_padding {
    padding: 22px 0 10px;
  }
}

.button-back--parent {
  background-color: $mainBackground;
  display: flex;
  justify-content: center;
}