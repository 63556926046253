@mixin PreviousRadioBtn {
  border-color: $greyForPlaceholders;
  background: $greyForPlaceholders;
  color: $oldBlue;
}

.wages__jobs-location-item {
  padding: 8px 12px;
  background: $inputBackground;
  border: 1px solid $inputBorderGreyColor;
  border-radius: 25px;
  color: $darkBlue;
  margin-right: 6px;
  font-weight: normal;

  &:lang(en) {
    padding: 8px 12px;
  }
  &:lang(ru) {
    padding: 8px 10px;
    @media screen and (max-width: 385px){
      padding: 8px;
    }
  }

  @media screen and (max-width: 374px) {
    &:lang(en) {
      padding: 8px 9px;
    }
    &:lang(ru) {
      padding: 8px 8px;
    }
  }

  @media screen and (max-width: 359px) {
    &:lang(en) {
      padding: 8px 5px;
    }
    &:lang(ru) {
      padding: 8px 5px;
    }
  }

  @media screen and (max-width: 330px) {
    &:lang(ru) {
      padding: 8px 3px;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &_active {
    background: $orangeActive;
    border-color: $orangeActive;
    color: white;
  }

  &_old {
    @include PreviousRadioBtn();
  }
}

.wages__position {
  display: grid;
  grid-template-columns: repeat(2, minmax(120px, 1fr));
  grid-gap: 12px;
  grid-auto-flow: row;

  &-item {
    background: $mainColor;
    border: 1px solid #C2CFE5;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 23px;
    line-height: 110%;
    color: $darkBlue;
    font-weight: 600;

    &_padding_sm {
      padding: 12px 6px;
    }

    & img {
      margin-right: 6px;
    }

    &_active {
      background: $orangeActive;
      border-color: $orangeActive;
      color: white;
    }

    &_old {
      @include PreviousRadioBtn();
    }

    &-text {
      width: min-content;
    }
  }
}

.wages-two-inputs-in-a-row {
  display: flex;
}