.summary-header {

  &__switcher {
    display: flex;
    width: 100%;
    background: $inputBackground;
    border-radius: 9px;
    padding: 2px;

    &-button {
      color: $darkBlue;
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 18px;
      padding: 10px 0 12px;
      flex-grow: 1;
      width: 50%;
      border-radius: 8px;
      margin: 0;

      &_active {
        background: $orangeActive;
        color: white;
      }
    }
  }

  &__weeks {
    margin-top: 12px;
    display: flex;

    &-button {
      color: $darkBlue;
      font-weight: 600;
      font-size: 1rem;
      line-height: 19px;
      padding: 8px 12px;
      margin-right: 6px;
      background: #F6F9FD;
      border: 1px solid #CEDCED;
      box-sizing: border-box;
      border-radius: 25px;

      &:last-child {
        margin-right: 0;
      }

      &_active {
        background: $orangeActive;
        border: 1px solid $orangeActive;
        color: white;
      }
    }
  }
}

.summary-list__wages {
  margin-top: 18px;

  &-item {
    background: white;
    border: 1px solid $inputBorderGreyColor;
    padding: 14px 16px;

    &:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &-price {
        color: $darkBlue;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        position: relative;
        display: inline-block;
        word-break: break-all;
        width: fit-content;

        &_updated {
          &:after {
            content: ' ';
            display: block;
            width: 6px;
            height: 6px;
            position: absolute;
            top: 0;
            right: -8px;
            border-radius: 50px;
            background: $darkError;
          }
        }
      }

      &-status {
        display: flex;
        color: $reviewed;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;

        &-image {
          margin-left: 4px;
        }

        &_error {
          color: $darkError;
        }
      }
    }

    &-date {
      margin-top: 5px;
      color: $blueGray;
      font-size: 1rem;
      line-height: 19px;

      &_bold {
        font-weight: 700;
      }
    }

    &-job {
      margin-top: 8px;
      font-weight: 600;
      font-size: 1rem;
      line-height: 19px;
      color: $blueGray;
    }
  }
}

.summary-list__expenses {
  margin-top: 18px;

  & a:first-child .summary-list__expenses-item {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  & a:last-child .summary-list__expenses-item {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &-item {
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    border: 1px solid #C9D4E8;
    padding: 14px 16px;

    &-content {
      display: flex;
    }

    &-preview {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background: $inputBorderGreyColor;
      display: flex;
      justify-content: center;
      align-items: center;

      &-final {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        object-fit: cover;
      }

      &-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
      }

      &-image {
        object-fit: cover;
      }
    }

    &-main {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-description {
        font-size: 14px;
        line-height: 110%;
        color: $darkBlue;
        max-height: 31px;
        overflow-y: hidden;
      }
    }

    &-dates {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;

      &-content {
        text-align: right;
      }

      &-weekday {
        color: $blueGray;
        font-size: 1rem;
        line-height: 19px;
        font-weight: 700;
      }

      &-month {
        margin-top: 2px;
        color: $blueGray;
        font-size: 1rem;
        line-height: 19px;
      }

      &-image {
        width: 20px;
      }
    }
  }
}

.summary-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 52px;

  &__text {
    margin-top: 24px;
    font-weight: 600;
    line-height: 19px;
    color: $darkBlue;
    text-align: center;
    max-width: 200px;
  }
}

.summary-list__loader {
  margin-top: 22vh;
}















