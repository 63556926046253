.main-layout {
  min-height: 100vh;
  position: relative;
  max-width: $maxWidth;
  width: 100%;
  padding-bottom: 130px;

  &-container {
    background-color: $mainBackground;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}