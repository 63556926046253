h1, .title {
  font-weight: 800;
  font-size: 28px;
  line-height: 125%;
  color: $darkBlue;
}

.title_padding {
  padding-bottom: 40px;
}

.form-error {
  font-size: 14px;
  color: $error;

  &_padding {
    padding-top: 9px;

    &_small {
      padding-top: 7px;
    }
  }
}

.text__edited-by-admin {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: $greyForPlaceholders;
}