.bottom-navbar {
  display: flex;
  position: fixed;
  bottom: 0;
  background-color: white;
  height: $bottomNavHeight;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: $maxWidth;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 2;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;

    &-text {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: $blueGray;

      &_active {
        color: $orangeActive;
      }
    }
  }

  &__total {
    background: $commonWhite;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px 12px 0 0;
    position: fixed;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: $maxWidth;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 18px 6px;
    border-bottom: 1px solid $mainBackground;

    &-text {
      font-weight: bold;
      font-size: $bigSize;
      line-height: $bigSizeLineHeight;
      color: $darkBlue;
    }
  }
}